import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { UsernameValidator } from "../../components/validators/userName.validator";
import { ForgotpwdService } from "./forgotpwd.services";

@Component({
  selector: "forgotpwd",
  templateUrl: "forgotpwd.component.html",
  styleUrls: ["forgotpwd.component.scss"],
})
export class ForgotpwdComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  error = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private forgotpwdService: ForgotpwdService,
  ) {
  }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      usernameEmail: ['', [Validators.required, UsernameValidator.cannotContainSpace]],
    });
  }

  get f() { return this.forgotForm.controls; }

  onSubmit(): void {
    const values = this.forgotForm.value;
    const params = {
      username: values.usernameEmail,
    }
    this.forgotpwdService.forgotpwd(params).subscribe(
      data => {
        this.router.navigate(["/login"]);
      },
      rsp => {
        switch(rsp.status) {
          case 404:
            this.setError("Usuario no encontrado");
            break;
          case 401:
            this.setError('Usuario bloqueado');
            break;
          default:
            this.setError(rsp.message);
        }
      });
  }

  setError(msg: string): void {
    this.error = msg;
    setTimeout(() => {
      this.error = "";
    }, 4000)
  }
}
