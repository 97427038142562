import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { UserService } from "src/app/pages/user/user.services";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  enabled: boolean;
  item?: any;
}
interface RoutesTitle {
  path: string;
  title: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/template",
    title: "Plantilla",
    icon: "icon-bag-16",
    class: "",
    enabled: false
  },
  {
    path: "/pos",
    title: "POS",
    icon: "icon-double-right",
    class: "",
    enabled: false
  },
  {
    path: "/pos-form",
    title: "POS",
    icon: "",
    class: "hiddenOption",
    enabled: false
  },
  {
    path: "/host",
    title: "Host",
    icon: "icon-laptop",
    class: "",
    enabled: false
  },
  {
    path: "/host-form",
    title: "Host",
    icon: "",
    class: "hiddenOption",
    enabled: false
  },
  {
    path: "/range",
    title: "Rango",
    icon: "icon-components",
    class: "",
    enabled: false
  },
  {
    path: "/range-form",
    title: "Rango",
    icon: "",
    class: "hiddenOption",
    enabled: false
  },
  {
    path: "/bank",
    title: "Banco",
    icon: "icon-bank",
    class: "",
    enabled: false
  },
  {
    path: "/bank-form",
    title: "Banco",
    icon: "",
    class: "hiddenOption",
    enabled: false
  },
  {
    path: "/lists",
    title: "Tablas",
    icon: "icon-bullet-list-67",
    class: "",
    enabled: false
  },
  {
    path: "/update-pos",
    title: "Actualización POS",
    icon: "icon-double-right",
    class: "",
    enabled: true
  },
  {
    path: "/user",
    title: "Perfil de usuarios",
    icon: "icon-single-02",
    class: "",
    enabled: false
  },
  {
    path: "/user-form",
    title: "Perfil de usuarios",
    icon: "",
    class: "hiddenOption",
    enabled: false
  },
  {
    path: "/audit",
    title: "Auditoria",
    icon: "icon-bullet-list-67",
    class: "",
    enabled: false,
    item: [
      {
        name: 'Actividad de Usuarios',
        path: "AUTENTICACION",
        icon: "dot-circle-o",
      },
      {
        name: 'Actualizaciones de POS',
        path: "POS",
        icon: "dot-circle-o",
      }
    ]
  },
];

const ROUTES_TITLE: RoutesTitle[] = [
  {
    path: '/date-eftsec-form',
    title: 'Fecha EFTSEC',
  },
  {
    path: '/key-eftsec-form',
    title: 'Llave EFTSEC',
  },
  {
    path: '/emv-form',
    title: 'EMV',
  },
  {
    path: '/emv-key-form',
    title: 'Llave EMV',
  },
  {
    path: '/emv-app-form',
    title: 'Aplicación EMV',
  },
  {
    path: '/issuer-form',
    title: 'Emisores',
  },
  {
    path: '/ccv-form',
    title: 'CCV',
  },
  {
    path: '/kind-affiliation-form',
    title: 'Tipo de Afiliaciones',
  },
  {
    path: '/kind-pin-form',
    title: 'Tipo de PIN',
  },
  {
    path: '/connection-type-form',
    title: 'Tipo de Conexión',
  },
]

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: RouteInfo[];
  location: Location;
  permissions: any = [];
  templateEnabled = false;
  posEnabled = false;
  hostEnabled = false;
  rangeEnabled = false;
  bankEnabled = false;
  tableEnabled = false;
  userEnabled = false;
  auditEnabled = false;
  updatePosEnabled = false;
  routes = ROUTES;

  queryParams = '';

  constructor(
    location: Location,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.loadPermissions();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.loadPermissions();
    });
  }

  loadPermissions() {
    this.userService.getActualUserPermission().subscribe(
      (lst) => {
        this.permissions = lst['data'].permissions;

        if (lst['data'].role == 'superadmin') {
          this.templateEnabled = true;
          this.userEnabled = true;
          this.bankEnabled = true;
          this.hostEnabled = true
          this.posEnabled = true;
          this.tableEnabled = true;
          this.rangeEnabled = true;
          this.auditEnabled = true;
          this.updatePosEnabled = true;
        } else {
          this.permissions.map((data: any) => {

            if (data.module == 'PLANTILLAS' && data.description == 'Listar Plantillas') {
              this.templateEnabled = true;
            }

            if (data.module == 'USUARIOS' && data.description == 'Listar Usuarios') {
              this.userEnabled = true;
            }

            if (data.module == 'BANCOS' && data.description == 'Listar Bancos') {
              this.bankEnabled = true;
            }

            if (data.module == 'POS' && data.description == 'Listar POS') {
              this.posEnabled = true;
            }

            if (data.module == 'APPS' && data.description == 'Listar Aplicativos') {
              this.updatePosEnabled = true;
            }

            if (data.module == 'TABLAS' && data.description == 'Listar Hosts') {
              this.hostEnabled = true
            }

            if (data.module == 'TABLAS' && data.description == 'Listar Rangos') {
              this.rangeEnabled = true;
            }

            if (data.module == 'LOGS' && data.description == 'Listar logs') {
              this.auditEnabled = true;
            }

            if (
                data.description == 'Listar Hosts' ||
                data.description == 'Listar Fechas EFTSec' ||
                data.description == 'Listar Llaves EFTSec' ||
                data.description == 'Listar Aplicación EMV' ||
                data.description == 'Listar EMV' ||
                data.description == 'Listar Llaves EMV' ||
                data.description == 'Listar Tipos de Afiliación' ||
                data.description == 'Listar Entrada CVVs' ||
                data.description == 'Listar Emisores' ||
                data.description == 'Listar Tipos de PIN' ||
                data.description == 'Listar Tipos de Conexión' ||
                data.description == 'Listar Entrada de Datos' ||
                data.description == 'Listar Tipos de Servicios' ||
                data.description == 'Listar Hosts'
            ) {
              this.tableEnabled = true;
            }
          })
        }
        const tmpRoutes = this.routes;

        this.routes.map((el, index) => {
          if (el.title == 'Plantilla') {
            tmpRoutes[index].enabled = this.templateEnabled
          }

          if (el.title == 'Perfil de usuarios') {
            tmpRoutes[index].enabled = this.userEnabled
          }

          if (el.title == 'Banco') {
            tmpRoutes[index].enabled = this.bankEnabled
          }

          if (el.title == 'POS') {
            tmpRoutes[index].enabled = this.posEnabled
          }

          if (el.title == 'Host') {
            tmpRoutes[index].enabled = this.hostEnabled
          }

          if (el.title == 'Rango') {
            tmpRoutes[index].enabled = this.rangeEnabled
          }

          if (el.title == 'Tablas') {
            tmpRoutes[index].enabled = this.tableEnabled
          }

          if (el.title == 'Actualización POS') {
            tmpRoutes[index].enabled = this.updatePosEnabled
          }

          if (el.title == 'Auditoria') {
            tmpRoutes[index].enabled = this.auditEnabled
          }
        })

        this.routes = tmpRoutes;
      }
    )
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    titlee = titlee.split('?')[0];
    for (var item = 0; item < this.menuItems.length; item++) {
      if (this.menuItems[item].path === titlee) {
        if(this.menuItems[item].item != undefined && this.menuItems[item].item.length > 0){
          this.route.queryParams
          .subscribe(params => {
            this.queryParams = params.module;
          }
        );
        }
        return this.menuItems[item].title;
      }
    }

    let title = '';
    titlee = '/' + titlee.split('/')[1];
    ROUTES_TITLE.map((el: RoutesTitle) => {
      if (titlee === el.path) {
        title = el.title;
      }
    });


    return title === '' ? "" : title;
  }
}
