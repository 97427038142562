import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './login.services';
import { UsernameValidator } from "../../components/validators/userName.validator";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { NgIdleService } from "src/app/components/config/idle.service";
import { initiateFirstTimer, initiateSecondTimer } from "src/app/components/config/idle"
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild('modalCode') modalCode;
  modalOption: NgbModalOptions = {};
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  reCaptchaCode: string = '';
  error = '';
  showPwd = false;

  codeValid = false;
  isCodeCorrect = false;
  verificationCode = '';
  codeError = '';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  isFirstTime = false;

  token2FA = '';

  modalReference: NgbModalRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private ngIdle: NgIdleService,
    private modalService: NgbModal,
    private _snackService: MatSnackBar,
    private http: HttpClient,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    console.log(this.authenticationService.currentUserValue)
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      user: ['', [Validators.required, UsernameValidator.cannotContainSpace]],
      password: ['', Validators.required],
    });
  }

  get f() { return this.loginForm.controls; }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;

    fetch("https://api.ipify.org/?format=json", { mode: 'cors' })
    .then(rsp => rsp.json())
    .then(res => {
      this.authenticationService.login(this.f.user.value, this.f.password.value, this.reCaptchaCode, res.ip)
        .pipe(first())
        .subscribe(
            data => {
              console.log(data);
              //localStorage.setItem('currentUser', JSON.stringify(data))
              //localStorage.setItem('auth', data.token);
              //this.isFirstTime = data.first_time;
              this.token2FA = data.token;
              this.openModalCode(this.modalCode);
              /*
              if (data.first_time) {
                this.router.navigate(["/change"]);
              } else {
                if(this.returnUrl == "/"){
                  this.router.navigate(["/template"]);
                }else{
                  this.router.navigate([this.returnUrl]);
                }
              }*/
            },
            rsp => {
              console.log(rsp);
              if (rsp.status == 500) {
                this.openSnackBar('Error interno');
              } else if (rsp.data === "Bad credentials"){
                this.openSnackBar('Error en Contraseña, si la introduce 3 veces mal su usuario sera bloqueado');
              } else if (rsp.message === "User credentials have expired") {
                this.openSnackBar('Contraseña expiro, debe hacer el proceso de recuperación');
              } else if(rsp.data === 'Invalid or expired captcha'){
                this.openSnackBar('Captcha expirado o invalido');
                grecaptcha.reset();
              } else if(rsp.data == 'User is disabled'){
                this.openSnackBar('Usuario bloqueado');
              } else if(rsp.dara == "Bad credentials. User was disabled"){
                this.openSnackBar('Error en Contraseña, usuario bloqueado');
              } else if (rsp.data == 'OTP already expired') {
                this.openSnackBar('Este código ha expirado, Inicie sesión nuevamente');
              } else if (rsp.data.includes('OTP not match')) {
                this.openSnackBar('Error en código, si introduce 3 veces mal su usuario sera bloqueado');
              } else {
                this.openSnackBar(rsp.data);
              }
            });
    })
  }


  initTimer(firstTimerValue: number, secondTimerValue: number): void {
    // Timer value initialization
    this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN = firstTimerValue;
    this.ngIdle.FINAL_LEVEL_TIMER_VALUE_IN_MIN = secondTimerValue;
    // end

    // Watcher on timer
    this.ngIdle.initilizeSessionTimeout();
    this.ngIdle.userIdlenessChecker.subscribe((status: string) => {
      initiateFirstTimer(status);
    });

    this.ngIdle.secondLevelUserIdleChecker.subscribe((status: string) => {
      initiateSecondTimer(status);
    });
   }

  setError(msg: string): void {
    this.error = msg;
    setTimeout(() => {
      this.error = "";
    }, 4000)
  }

  resolved(captchaResponse: string) {
    this.reCaptchaCode = captchaResponse;
  }

  openModalCode(content) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalReference = this.modalService.open(content, this.modalOption);
  }



  // Reseteamos los valores en caso de que este cambiando el codigo el usuario
  onCodeChanged(e: string): void {
    this.codeValid = e.length === 6;
    this.verificationCode = e;
    this.isCodeCorrect = true;
    this.codeError = '';
  }

  // En caso de que el codigo se complete, lo validamos con la API
  async onCodeCompleted(): Promise<void> {
    try {
      // Validamos el codigo
      this.authenticationService.validate2FA({code: this.verificationCode, token: this.token2FA}).subscribe(
        (data: any) => {
          console.log(data);
          this.modalReference.close(false);
          localStorage.setItem('currentUser', JSON.stringify(data))
          localStorage.setItem('auth', data.token);
          localStorage.setItem('refrest_tk', data.refresh_token);
          this.isFirstTime = data.first_time;

          if (data.first_time) {
            this.router.navigate(["/change"]);
          } else {
            this.router.navigate(["/template"]);
          }

        },
        rsp => {
          console.log(rsp);
          if (rsp.data === "Bad credentials"){
            this.openSnackBar('Error en Contraseña, si la introduce 3 veces mal su usuario sera bloqueado');
          } else if (rsp.message === "User credentials have expired") {
            this.openSnackBar('Contraseña expiro, debe hacer el proceso de recuperación');
          } else if(rsp.data === 'Invalid or expired captcha'){
            this.openSnackBar('Captcha expirado o invalido');
            grecaptcha.reset();
          } else if(rsp.data == 'User is disabled'){
            this.openSnackBar('Usuario bloqueado');
          } else if(rsp.dara == "Bad credentials. User was disabled"){
            this.openSnackBar('Error en Contraseña, usuario bloqueado');
          } else if (rsp.data == 'OTP already expired') {
            this.openSnackBar('Este código ha expirado, Inicie sesión nuevamente');
            this.modalReference.close(false);
          } else if (rsp.data.includes('OTP not match')) {
            this.openSnackBar('Error en código, si introduce 3 veces mal su usuario sera bloqueado');
          } else {
            this.openSnackBar(rsp.data);
          }
          this.loading = false;
        });
    } catch (e) {
      console.log(e);
    }
  }

  submit() {
    this.router.navigate(["/template"]);
  }

  openSnackBar(msg: string): void {
    this._snackService.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
