import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../../components/config/Endpoints';
import { ChangeModel } from '../login/Auth';

@Injectable({ providedIn: 'root' })
export class ChangeService {
    
    constructor(private http: HttpClient) {}

    changePwd(changeParams: ChangeModel): Observable<ChangeModel>{
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);

        return this.http.post<ChangeModel>(`${Constants.changeUrl}`, changeParams, {headers:headers})
        .pipe(tap(data => {
            if(data['message'] == 'success' ){
                return data['data']
            }else{
                // return 
            }
        }));
    }
}