import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Constants } from '../../components/config/Endpoints';
// import { User } from '../user/user';
// import { AuthModel } from './../../login/Auth'
import { User } from './user'

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) {}

    newUser(new_user:User): Observable<User> {
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);

        return this.http.post<User>(`${Constants.usersBaseUrl}`, new_user, {headers:headers})
        .pipe(tap(data => {
            if(data['message'] == 'success' ){
                return data['data']
            }else{
                // return
            }
        }));
    }

    updateUser(id: string, user:User): Observable<User> {
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.post<User>(`${Constants.usersEditBaseUrl}/${id}`, user, {headers:headers});
    }

    updateRoleName(id: string, params: any): Observable<any> {
      let headers = new HttpHeaders();
      const auth = localStorage.getItem('auth');
      headers = headers.set('Authorization', 'Bearer '+auth);
      return this.http.post<any>(`${Constants.rolesBaseUrl}/${id}`, params, {headers:headers});
  }

    deleteUser(id: string): Observable<User> {
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.post<User>(`${Constants.usersDeleteBaseUrl}/${id}`, {}, {headers:headers});
    }

    listUser(page = 0, size = 0, sortBy = '', sortDirection = '', filter = ''): Observable<User[]> {
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.get<User[]>(`${Constants.usersBaseUrl}?page=${page}&size=${size == 0 ? '' : size}&sortBy=${sortBy}&sortDirection=${sortDirection}&filter=${filter}`, {headers: headers})
        .pipe(tap(data => {
            if(data['message'] == 'success' ){
                return data['data']
            }
        }));
    }

    entryUser(id: string): Observable<User> {
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.get<User>(`${Constants.usersBaseUrl}/${id}`, {headers: headers});
    }

    getListPermissions(): Observable<any>{
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.get<any>(`${Constants.privilegesBaseUrl}`, {headers: headers});
    }

    getListRoles(): Observable<any>{
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.get<any>(`${Constants.rolesBaseUrl}`, {headers: headers});
    }

    getPermissionByRole(id: string): Observable<any>{
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.get<any>(`${Constants.privilegesRoleUrl}/${id}`, {headers: headers});
    }

    getActualUserPermission(): Observable<any>{
        let headers = new HttpHeaders();
        const auth = localStorage.getItem('auth');
        headers = headers.set('Authorization', 'Bearer '+auth);
        return this.http.get<any>(`${Constants.userPermissionBaseUrl}`, {headers: headers});
    }
}
